<template>
  <DivisiManajemenForm mode="Ubah" module="Laporan Divisi Manajemen"> </DivisiManajemenForm>
</template>

<script>
import DivisiManajemenForm from './form';

const DivisiManajemenUpdate = {
  name: 'DivisiManajemenUpdate',
  components: { DivisiManajemenForm },
};

export default DivisiManajemenUpdate;
</script>
